@use "../helper";

.single-work {

  nav #menu-item-224 a {
    color: helper.$blue;
  }

  .meta {
    margin-top: 71px;

    .container {
      display: flex;
      justify-content: space-between;
    }

    .metas {
      font-size: 0.813rem;
      display: none;

      span {
        display: block;
        margin-bottom: 0.8rem;
        margin-top: 0.8rem;
      }

      &.active {
        display: block;
      }
      p {
        font-weight: 600;
        position: relative;
        padding-top: 1rem;

        &:before {
          position: absolute;
          content: "";
          width: 10000%;
          margin-top: -0.8rem;
          left: -2000%;
          border-top: 1px solid black;
        }
      }
    }
    @media screen and (max-width: helper.$lg) {
      margin-top: 0;

      .container {
        flex-wrap: wrap;

        .metas {
          min-width: 30%;
          display: none;
          position: relative;
          margin-bottom: 2.5rem;

          span {
            &:before {
              position: absolute;
              content: "";
              width: 10000%;
              margin-top: -0.8rem;
              left: -2000%;
              border-top: 1px solid black;
            }
          }

          &.active {
            display: block;
          }
        }
      }
    }
  }

  .social-links {
    display: flex;

    li {
      margin-right: 0.8rem;
    }

    svg {
      height: 1.2rem;
    }
  }

  .blog-header {
    padding-bottom: 5rem;
    
    .breadcrumb {
      font-size: 0.875rem;
      color: helper.$blue;
      
      a {
        color: helper.$blue;
      }
    }

    .blurb-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 4rem;
      padding-right: 4rem;

      @media screen and (max-width: helper.$lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .heading {
      font-size: 2.5rem;
      line-height: 1.2;

      @media screen and (max-width: helper.$lg) {
        font-size: 1.8rem;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .img-container {
      height: 34rem;
      position: relative;
      
      @media screen and (min-width: helper.$xxl) {
        height: 38rem;
      }
  
      @media screen and (max-width: helper.$lg) {
        height: 21rem;

        img {
          width: 110% !important;
          margin-left: -5%;
        }
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (max-width: helper.$lg) {
      margin-top: 71px;
      padding-bottom: 0;
      margin-bottom: 3rem;

      .blurb-section {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
  }

  .context {
    font-size: 1.125rem;

    ul {
      list-style: revert;
    }
    
    @media screen and (max-width: helper.$lg) {
      font-size: 1rem;
    }

    .row {
      border-top: 1px solid black;
      padding: 2rem 0;

      @media screen and (max-width: helper.$lg) {
        padding: 1.5rem ​0 5rem;
      }
    }

    .col-lg-5 {
      p {
        @media screen and (max-width: helper.$lg) {
          font-size: 1.125rem;
        }
      }
    }

    .col-lg-7 {
      font-weight: 600;
      letter-spacing: -0.45px;
      b {
        font-weight: 500;
        letter-spacing: 0;
      }

      a:not(.work-post) {
        color: helper.$blue;
        position: relative;
      }
    }
  }

  .video {

    p {
      margin-bottom: 0;
    }
    
    iframe {
      min-height: 36rem;
      
      @media screen and (min-width: helper.$xxl) {
        min-height: 42rem
      }
    }
    padding-bottom: 5rem;

    @media screen and (max-width: helper.$lg) {
      .main-container {
        padding: 0 1rem !important;

        iframe {
          height: 12rem !important;
        }
      }
    }
  }

  .teams {
    .col-lg-7 {
      display: flex;
      flex-wrap: wrap;

      .team {
        min-width: 30%;
        margin-right: 3%;

        @media screen and (max-width: helper.$lg) {
          min-width: 50%;
          margin-bottom: 2.5rem;
        }
        
        .heading {
          font-weight: 500;
          margin-bottom: 1.2rem;
          display: block;
        }
      }
    }
  }

  .assets {
    .cta {
      border: none;
      position: relative;
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      text-transform: uppercase;
      font-weight: 500;

      &:before {
        content: "";
        position: absolute;
        width: 0%;
        left: 0;
        bottom: 8px;
        height: 1px;
        background: helper.$blue;
        @extend .fastTransition;
      }
  
      &:hover{
        &:before {
          width: 100%;
        }
      }
    }
  }

  .related-work {
    .col-lg-7 {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: helper.$lg) {
        flex-direction: column;
      }
    }

    .meta {
      margin-top: 0;
    }

    .work-post {
      margin-bottom: 4rem;
      border: 1px solid helper.$white;
      max-width: 45%;
      @extend .fastTransition;

      @media screen and (max-width: helper.$lg) {
        max-width: 100%;
      }
   
      &:hover {
        color: initial;
        border-color: helper.$black;
      }
  
      .meta {
        .date {
          font-size: 0.875rem;
          font-family: 'IBMPlexMono' !important;
          text-transform: uppercase;
          line-height: 1.2;
          width: 30%;
          font-weight: 400;
        }
  
        .authors {
          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.2;
          max-width: 70%;
          padding-left: 1.5rem;
        }
      }
  
      a {
        color: initial;
        padding: 0 1.5rem;
      }
  
      img {
        width: 100%;
        height: 17rem;
        object-fit: cover;
      }
  
      .main-content {
        position: relative;
        border-top: 1px solid black;
        margin-top: 2rem;
        padding-top: 2rem;
      }
  
      .cat {
        position: absolute;
        top: -15px;
        left: 0;
        display: flex;
        border-radius: 17px;
        font-size: 12px;
        text-transform: uppercase;
        padding: 6px 12px 6px 9px;

  
        &:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 15px;
          background: white;
          right: -15px;
          top: 0;
          z-index: 0;
        }
  
        span {
        font-size: 33px;
          line-height: 12px;
          margin-top: 4px;
          margin-right: 5px;
        }
      }
  
      .post-heading {
        font-size: 1.45rem;
        line-height: 1.5;
        font-weight: 400;
      }

      .post-blurb {
        font-size: 1rem;
      }
  
      @media screen and (max-width: helper.$lg) {
        padding: 1rem 0;
  
        img {
          min-height: 13rem;
        }
      }
    }
  }
}