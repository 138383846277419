@use "../helper";

.what-we-do {
  padding: 8rem 0 5rem;
	border-bottom: 1px solid black;

  .section-1 {

		display: flex;
		flex-direction: column;
		align-items: end;
		justify-content: space-between;
		border-right: 1px solid black;

    .heading {
      font-size: 1.125rem;
      text-transform: uppercase;
			width: 100%;
    }

    .blurb {
      max-width: 18rem;
      font-size: 1.875rem;
      font-weight: 600;
      line-height: 1.2;
      p {
        margin-bottom: 2rem;
        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }
    }

		@media screen and (max-width: helper.$lg) {
			align-items: center;
			justify-content: center;
			text-align: center;
		}
  }

  .section-2, .section-3 {
    .heading {
      font-size: 1.43rem;
      letter-spacing: -0.23px;
      margin-bottom: 3rem;
    }
    .blurb {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      img {
        width: 7rem;
        margin-left: -2rem;
      }
    }
  }

  @media screen and (max-width: helper.$lg) {
    padding: 5rem 0;

    .section-1 {
			border-right: 0;

      .blurb {
        padding-bottom: 5rem;
				font-size: 1.53rem;
				max-width: 100%;
      }
    }

		.section-2, .section-3 {
			&.section-2 {
				padding-bottom: 5rem;
			}
			text-align: center;

			.cta {
				margin: auto;
				margin-bottom: 1rem;
			}

			.left {
				padding-top: 5rem;
				flex-direction: column-reverse;
				justify-content: center;
				align-items: center;
				border-top: 1px solid black;

				img {
					margin-left: 0;
					width: initial;
					margin-bottom: 4rem;
				}
			}
		}
  }

  .cta {
    border: none;
    position: relative;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    text-transform: uppercase;
    font-weight: 500;
    color: helper.$blue;

    &:before {
      content: "";
      position: absolute;
      width: 0%;
      left: 0;
      bottom: 8px;
      height: 1px;
      background: helper.$blue;
      @extend .fastTransition;
    }

    &:hover{
      &:before {
        width: 100%;
      }
    }
  }
}
