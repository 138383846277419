@use "../helper";

.collaborating-researchers {
	border-top: 1px solid black;
	padding: 5rem 0 4rem;

	.heading {
		max-width: 60%;
    font-size: 1.125rem;
    text-transform: uppercase;

		@media screen and (max-width: helper.$lg) {
			max-width: 100%;
			text-align: center;
		}
	}

	.team-members {
		@media screen and (min-width: helper.$lg) {
			.col-lg-2 {
				width: 20% !important;
			}
		}
	}

	.member {
		.name {
			font-weight: 600;
		}

		a {
			.name {
				text-decoration: underline;
			}
		}

		.title {
			text-shadow: 0px 0px 0.5px black;
		}
		@media screen and (max-width: helper.$lg) {
			text-align: center;
		}
	}
}
