@use "../helper";

.hero {
  background: helper.$blue;
  min-height: 100vh;
  display: flex;
  align-items: center;
  z-index: 5;

  .heading {
    font-size: 4.4rem;
    line-height: 1;
    letter-spacing: -0.7px;
    max-width: 40rem;
    color: helper.$white;
    font-weight: 600;
    position: relative;

    img {
      max-height: 1.875rem;

      @media screen and (min-width: helper.$lg) {
        max-height: 2.875rem;
      }
    }

    span.typed-cursor {
      display: none !important;
    }

    .left-arrow {
      @media screen and (min-width: helper.$lg) {
        position: absolute;
        left: -2.8rem;
        top: 1rem;
      }
      @media screen and (max-width: helper.$lg) {
        margin-right: 1rem;
        margin-bottom: 5px;
      }
    }

    .right-arrow {
      margin-left: -0.5rem;
    }

    p {
      display: inline;
    }

    @media screen and (max-width: helper.$lg) {
      font-size: 2.6rem;
      letter-spacing: -0.5px;
      max-width: 34rem;
    }
  }
  
  .main-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 71px;
  }
}