@use "../helper";

.textarea {
  padding: 3rem 0;
  .main-container {
    p {
      letter-spacing: -0.5px;
      font-weight: 600;
      line-height: 1.1;

			&:nth-last-of-type(1) {
				margin-bottom: 0;
			}
		}

    @media screen and (max-width: helper.$lg) {
      padding: 0 1rem !important;
    }
  }

  a {
    text-decoration: underline;
  }

  ul {
    list-style: revert;
  }
}
