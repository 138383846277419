@use "../helper";

.project-groups {

	.blurb-section {
		align-items: center;
    justify-content: center;
    display: flex;

		.main-content {
			width: 72%;

			@media screen and (max-width: helper.$lg) {
				text-align: center;
				width: 100%;
				margin-top: 4rem;
			}
		}
	}

	.heading {
    font-size: 2.188rem;
		line-height: 1.2;

		@media screen and (max-width: helper.$lg) {
      font-size: 1.875rem;
    }
  }

  .blurb {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.2;
  }

  .cta {
    margin-top: 2.5rem;
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;
		padding: 0.6rem 1.5rem;

    @media screen and (max-width: helper.$lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .img-container {
    height: 22rem;
    position: relative;

    img {
      width: 100%;
			height: 100%;
			object-fit: cover;
    }

		@media screen and (min-width: helper.$xxl) {
			height: 26rem;
		}

		@media screen and (max-width: helper.$lg) {
			height: 13.1rem;
		}
  }

	.project {
		padding: 6rem 0;
		position: relative;

		@media screen and (max-width: helper.$lg) {
			padding: 4rem 0;
		}

		&:after {
			content: "";
			width: 105%;
			height: 1px;
			border-bottom: 1px solid black;
			position: absolute;
			bottom: 0;
			left: -2.5%;
		}

		&:nth-last-of-type(1) {
			&:after {
				display: none;
			}
		}
	}
}
