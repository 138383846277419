@use "../helper";

.general-inquiries {
  border-top: 1px solid black;
  padding-top: 5rem;
  padding-bottom: 8rem;

  .col-lg-5 {
    .heading {
      font-size: 1.4rem;
    }  
  }

  .col-lg-7 {
    font-size: 1.125rem;

    .blurb {
      font-weight: 600;
    }
  }

  .gfield {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: helper.$lg) {
    padding-top: 4rem;
    padding-bottom: 5rem;

    .main-container {
      padding: 0 1rem !important;
    }

    .cta {
      margin-top: -1rem !important;
      margin: auto !important;
    }

    .row * {
      padding-left: 0;
      padding-right: 0;
    }

    iframe {
      width: 100%;
    }
  }
} 

.page-404 {
  padding: 6rem 0;
  .cta {
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;

    &:hover {
      svg {
        margin-left: 25px;
      }

      color: helper.$blue;
      background-color: white;
    }

    @media screen and (max-width: helper.$lg) {
      margin-left: 0;
    }
  }
}