@use "../helper";

.social {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  li {
    margin-left: 0.8rem;
    a {
      display: block;
      position: relative;
    
      svg {
        position: absolute;
        max-height: 54%;
        width: 55%;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &[data-icon="instagram"],
        &[data-icon="youtube"] {
          width: 90%;
          max-height: 63% !important;
        }
      }
    }
  }
}