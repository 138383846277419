@use "../helper";

.post-type-loader {
  position: relative;
  padding-bottom: 8rem;
  border-bottom: 1px solid black;

  @media screen and (max-width: helper.$lg) {
    padding-bottom: 5rem;
  }
  
  &_header {
    border-top: 1px solid helper.$black;
    border-bottom: 1px solid helper.$black;
    position: relative;

    .slider-nav {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;

      @media screen and (max-width: helper.$lg) {
        display: none !important;
      }

      img {
        @extend .fastTransition;
      }

      .slick-disabled {
        pointer-events: none;
      }

      
        &__prev-arrow:hover {
          img {
            margin-right: 8px;   
          }
        }
        &__next-arrow:hover {
          img {
            margin-left: 8px;   
          }
        }

      & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 107px;

        &.slick-hidden {
          display: none !important;
        }

        &.Video {
          .date {
            padding-top: 1.1rem;
            br {
              display: none;
            }
          }
    
          .video-only {
            display: inline-block !important;
          }
    
          .main-content {
            border-top: 0;
            margin-top: 0;
    
            .post-heading {
              font-size: 1.438rem;
            }
    
            .post-blurb {
              display: none;
            }
          }
        }

        &:nth-child(1) {
          border-left: 1px solid helper.$black;
          border-right: 1px solid helper.$black;
        }

        &.slick-disabled {
          img {
            opacity: 0.1;
          }
        }
      }
      img {
        filter: invert(1);
        max-height: 2.05rem;
        @extend .fastTransition;
      }
    }
  }
  .heading {
    font-size: 3.125rem;
    text-align: center;
    padding: 1rem 0;

    @media screen and (max-width: helper.$lg) {
      font-size: 1.87rem
    }
  }

  .cta {
    margin: auto;
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;
  }

  .slick-list {
    overflow: visible;
    margin: 4rem 0;
    margin-left: -1.5rem;
  }

  .slick-slide {
    border-right: 1px solid black;
    max-width: 32rem;
    &:nth-last-child(1) {
      border-right: 0;
    }

    @media screen and (max-width: helper.$lg) { 
      max-width: 27rem;
    }

    .meta {
      .date {
        font-size: 0.875rem;
        font-family: 'IBMPlexMono';
        text-transform: uppercase;
        line-height: 1.2;
        min-width: 35%;
      }

      .authors {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.2;
        padding-left: 1.5rem;
      }
    }

    a {
      color: initial;
      padding: 0 2rem;
    }

    img {
      width: 100%;
      height: 17rem;
      object-fit: cover;
    }

    .main-content {
      position: relative;
      margin-top: 2rem;
      padding-top: 2rem;
    }

    .post-heading {
      font-size: 1.45rem;
      letter-spacing: 1.5;
    }
  }
}