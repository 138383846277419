@use "../helper";

nav {
  top: 0;
  z-index: 200;
  width: 100%;
  position: absolute !important;
  border-bottom: 1px solid helper.$black;

  .container {
    max-width: initial;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;

    @media screen and (min-width: helper.$md) {
      max-width: 940px;
    }

    @media screen and (min-width: helper.$lg) {
      max-width: 1340px;
    }

    @media screen and (min-width: helper.$xxl) {
      max-width: 1600px;
    }
    
    @media screen and (max-width: helper.$lg) {
      width: 100% !important;
    }
  }

  a.navbar-brand {
		overflow: hidden;
		position: relative;

    img {
      height: 27px;

      @media screen and (max-width: helper.$lg) {
        height: 24px;
      }
    }
  }

  &.active {
    position: fixed !important;
  }

  .search-button {
    margin-left: 2rem;
    svg {
      width: 1.2rem;
    }
  }
}

#hamburger, #hamburger2 {
  background: none;
  z-index: 51;
  border: 0;
  outline: 0 !important;
  width: 20px;
  height: 20px;
  position: relative;

  span {
    right: 0;
    width: 100%;
    height: 2px;
    background: helper.$black;
    position: absolute;
    @extend .fastTransition;

    &:nth-child(1) {
      margin-top: -4px;
    }

    &:nth-child(2) {
      margin-top: 4px;
    }
  }
  
  &#hamburger2 {
    span {
      background: helper.$black;
    }
  }

  &.active {
    padding-top: 7px;

    span:nth-child(1) {
      transform: rotate(45deg);
      width: 105%;
    }
    span:nth-child(2) {
      transform: rotate(-45deg) translateY(-6px) translateX(6px);
    }
  }
}

.desktop-nav {

  li:nth-last-child(1) {
    a {
      margin-right: 0;
    }
  }
  
  a {
    position: relative;
    display: inline-block;
    padding: 0;    
    transition: transform 0.4s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    
    &:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      content: attr(data-name);
      transition: color 0.4s;
      transform: rotateX(-90deg);
      transform-origin: 50% 0;
      text-align: center;
    }

    &:hover:not(.search-button),
    &:focus:not(.search-button) {
      transform: rotateX(90deg) translateY(-22px);
      &:before {
        color: helper.$blue;
      }
    }
  }

  .current_page_item a {
    &:not(.search-button) {
      transform: rotateX(90deg) translateY(-22px);
      &:before {
        color: helper.$blue;
      }
    }
  }
}

.search-container {
  position: fixed;
  top: -300px;
  z-index: 500;
  width: 100%;
  background: white;
  border-top: 1px solid black;
  box-shadow: 0px 2px 6px rgb(177 177 177 / 30%);
  @extend .fastTransition;

  &.active {
    top: 71px;
  }

  &.search-page {
    top: 0;
    margin-top: 2rem;
    border: 0;
    box-shadow: none;
    
    form {
      justify-content: flex-start;

      input {
        @media screen and (max-width: helper.$lg) {
          width: 100%;
          max-width: 100%;
        }
        margin-bottom: 2rem;
      }
    }
  }

  form {
    display: flex;
    justify-content: flex-end;
    
    @media screen and (max-width: helper.$lg) {
      flex-direction: column;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid black;
    outline: none !important;
    width: 350px;
    max-width: 70%;
    font-size: 1.5rem;
    line-height: 1;
    padding: 0;
  }

  .cta {
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;
    margin-left: 2rem;
    margin-bottom: 0;

    &:hover {
      svg {
        margin-left: 25px;
      }

      color: helper.$blue;
      background-color: white;
    }

    @media screen and (max-width: helper.$lg) {
      margin-left: 0;
    }
  }
}

.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: -50;
  opacity: 0;
  visibility: hidden;
  background: helper.$white;
  transition: opacity 0.3s ease;
}

.navbar {

  a {
    color: black;
  }
  
  &.fixed {
    position: fixed !important;
    transform: translateY(-80px);
    transition: transform 0.3s ease;
    background: white;
    z-index: 10;

    &.show {
      transform: translateY(0);
    }
  }

  @media screen and (max-width: helper.$lg) {
    .navbar-brand {
      z-index: 100;
    }
  }

  & >.container {
    @media screen and (max-width: helper.$lg) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}

.mobile-menu-container {
  position: absolute;
  bottom: 2.5rem;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;

  .search-container {
    width: 100%;
    
    form, input {
      width: 100%;
      max-width: 100%;
    }

    input {
      margin-bottom: 1.5rem;
    }

    a {
      color: black !important;
    }
  }

  .search-button {
    margin-top: 0.5rem;
    margin-right: 1.5rem;

    svg {
      width: 2rem;
    }
  }
}

.navbar-nav {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  align-items: center;

  a {
    position: relative;
    margin: 0.25rem 1.5rem;
    color: helper.$black;
    font-size: 16px;
    font-family: 'MaisonNeue';
    text-align: center;
    display: block;
    text-decoration: none;
  }
}

#menu-main-menu-1 {
  flex-direction: column;
  align-items: flex-end;

  a {
    color: helper.$black;
    font-size: 2.5rem;
    font-weight: 600;
  }
}

.navbar.active {
  .mobile-menu {
    z-index: 0;
    opacity: 1;
    visibility: visible;
  }

  a.navbar-brand {
    img {
      filter: none !important; 
    }
  }
  #hamburger, #hamburger2 {
    span {
      background: helper.$black;
    }
  }
}

#mainNav, #mainNav2 {
  justify-content: flex-end;
}

#mainNavMobile {
  position: fixed;
  margin-top: 55px;
  z-index: 10;
  height: 100vh;
  width: 100%;

  .navbar-nav {
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 55px);
    background: white;
    margin-top: 0;

    li {
      padding: 0.9rem 0;
      margin: 0 1.5rem;
      border-bottom: 1px solid black;

      a {
        width: 100%;
        margin: 0;
        display: block;
      }
    }
  }
}