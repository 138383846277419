.fastTransition {
  transition: all 0.3s ease;
}

.slowTransition {
  transition: all 0.6s ease;
}

.extraSlowTransition {
  transition: all 1.6s ease;
}

.linearTransition {
  transition: all 0.3s cubic-bezier(.66, .22, .84, .45);
}

.scaled {
  & > div {
		transition: all 1.6s ease !important;
  }

  &:hover {
    & > div {
      transform: scale(1.12) !important;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.cat {
  position: absolute;
  top: -15px;
  left: 0;
  display: flex;
  border-radius: 17px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 12px 6px 9px;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 15px;
    background: white;
    right: -15px;
    top: 0;
    z-index: 0;
  }
  
  span {
  font-size: 33px;
    line-height: 12px;
    margin-top: 4px;
    margin-right: 5px;
  }
}