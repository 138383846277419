@use "../helper";

.text {
  .main-container {
    p {
      padding: 3rem 0;
			max-width: 85%;
      font-size: 3.125rem;
      letter-spacing: -0.5px;
      font-weight: 600;
      line-height: 1.1;

			&:nth-last-of-type(1) {
				margin-bottom: 0;
			}

			@media screen and (min-width: helper.$xxl) {
				max-width: 68%;
			}
		}
    @media screen and (max-width: helper.$lg) {
      padding: 0 4rem !important;
      p {
        font-size: 1.875rem;
        text-align: center;
        max-width: 100%;
      }
    }
  }
}
