@use "../helper";

.search-results {
  padding: 4rem 0 0;

  article {
    margin-bottom: 3rem;
    border-bottom: 1px solid black;
    padding-bottom: 2rem;
  }

  .posts-navigation {
    display: none !important;
  }
}