@use "../helper";

// CTA
.cta {
  @extend .fastTransition;
  svg {
    @extend .fastTransition;
  }
  &:hover {
    svg {
      margin-left: 25px;
    }

    color: helper.$blue;
    background-color: white;
  }
}

// IMG Scale hover

.img-container:not(.no-scale) {
  overflow: hidden;

  img {
    @extend .slowTransition;
  }

  &:hover {
    img {
      transform: scale(1.2);
    } 
  }
}