@use "../helper";

.image-with-text {
  padding: 8rem 0;

  @media screen and (max-width: helper.$lg) {
    padding-top: 5rem;
  }

  .blurb-section {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: helper.$lg) {
      padding-top: 6rem;
      text-align: center;
    }
  }

  .main-content {
    max-width: 30rem;
  }
  .sub-heading {
    font-size: 1.125rem;
  }
  .blurb {
    font-size: 2.188rem;
    letter-spacing: 1.05px;
    font-weight: 600;
    line-height: 1.2;
  }

  .row {
    @media screen and (max-width: helper.$lg) {
      flex-direction: column-reverse;

      .blurb {
        text-align: center;
        padding: 0 1rem;
        font-size: 1.6rem;
      }
    }
  }

  .cta {
    margin-top: 2.5rem;
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;

    @media screen and (max-width: helper.$lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .img-container {
    height: 32rem;
    position: relative;
    
    @media screen and (min-width: helper.$xxl) {
      height: 38rem;
    }
    @media screen and (min-width: helper.$lg) {
      padding-left: 20%;
    }

    @media screen and (max-width: helper.$lg) {
      height: 22rem;
    }

    img {
      width: 100%;
			height: 100%;
			object-fit: cover;
    }
  }
}