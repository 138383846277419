@use "../helper";

.get-in-touch {
  padding-top: 5rem;
  padding-bottom: 8rem;

  .heading {
    font-size: 1.4rem;
  }  

  .main-container {
    @media screen and (max-width: helper.$lg) {
      padding: 0 1rem !important;
    }
  }

  form {
    .gform_fields {
      grid-column-gap: 4% !important;
    }
    .gfield_label {
      font-size: 1.125rem !important;
      font-weight: 500 !important;
    }
    input, select {
      border: 0;
      outline: none;
      background: none;
      border-bottom: 1px solid black;
      padding-left: 0px !important;
    }

    input, select, textarea {
      &::placeholder {
        color: black;
        font-weight: 600;
        font-family: 'MaisonNeue';
      }
    }

    select {
      cursor: pointer;
    }

    textarea {
      height: 9.4rem !important;
    }

    .cta {
      background: helper.$blue;
      color: helper.$white;
      border-color: helper.$blue;
  
      &:hover {
        svg {
          margin-left: 25px;
        }
  
        color: helper.$blue;
        background-color: white;
      }
  
      @media screen and (max-width: helper.$lg) {
        margin-left: 0;
      }
    }
  }

  .gform_wrapper .validation_message {
    display: none !important;
  }

  .gform_wrapper .gfield_error [aria-invalid=true] {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .gfield {
    margin-bottom: 1.7rem;    
  }

  @media screen and (max-width: helper.$lg) {
    padding-top: 4rem;
    padding-bottom: 5rem;

    .row > * {
      padding-left: 0;
      padding-right: 0;
    }
    
    .cta {
      margin: auto;
    }
  }
}