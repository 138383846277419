// responsive
$xs: 25em;
$sm: 38em;
$md: 48em;
$lg: 67em;
$desktop: 75em;
$xl: 90em;
$xxl: 110em;

$black: #000;
$blue: #2300FF;
$white: #fff;