@use "helper";

body {
  -moz-osx-font-smoothing: grayscale;
  /*(For Firefox)*/
  -webkit-font-smoothing: antialiased;
}

hr {
	opacity: 1;
}

a:hover {
	color: inherit;
}

section {
  position: relative;
  overflow: hidden;
  &.overflow {
    overflow: hidden;
	  z-index: 1;
  }

	a {
		color: black;
    text-decoration: none;
	}
}

.gfield {
	outline: none !important;
}

.container {
	position: relative;
	z-index: 2;
	margin: 0 auto;
	padding: 0 1.5rem;

	@media screen and (min-width: helper.$md) {
		max-width: 940px;
	}

	@media screen and (min-width: helper.$lg) {
		max-width: 1340px;
	}

	@media screen and (min-width: helper.$xxl) {
		max-width: 1600px;
	}

	@media screen and (max-width: helper.$lg) {
		padding: 0 1rem !important;
	}
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.postid-1954 .project-hero .logo {
	filter: invert(1) contrast(10);
    max-width: 340px !important;
    max-height: initial !important;
    height: initial !important;
}

.postid-1998 .project-hero .logo {
	filter: invert(1) contrast(10) grayscale(1);
}

.post-password-form {
	margin: 5rem;
}

.splash-img {
	display: none !important;
}

.img-h-100 img {
	height: 100%;
	object-fit: cover;
}

.cta {
	border: 1px solid helper.$black;
	padding: 0.8rem 1.2rem;
	border-radius: 30px;
	display: table;
	svg {
		height: 18px;
		margin-left: 10px;
		margin-top: -2px;
		color: inherit;
	}

	@media screen and (max-width: helper.$lg) {
		padding: 0.6rem 2.5rem;
	}
}

.main-container {
	padding: 0 8rem !important;

	@media screen and (max-width: helper.$lg) {
		padding: 0 1rem !important;
	}
}

.accordion-button {
	:not(.collapsed)::after, &:after {
		background-position: right;
	}
	&:after {
		background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-chevron-down fa-w-14 fa-2x'%3E%3Cpath fill='%232300FF' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
	}
	:not(.collapsed)::after {
		background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-up' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-chevron-up fa-w-14 fa-2x'%3E%3Cpath fill='%232300FF' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
	}
}

.inline-video {
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: helper.$lg) {
		flex-direction: column;
	}

	iframe {
		margin: 0 1rem;
	}
}

p {
	b {
		text-shadow: 0px 0px 1px black;
	}
}

.slick-dots {
	padding: 0;
}