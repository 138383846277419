@use "../helper";

.news {

  #mobile-filter {
    border-bottom: 1px solid #000;
    text-align: right;


    a {
      line-height: 2.9rem;
      margin-right: 1rem;

      svg {
        width: 10px;
        position: absolute;
        top: -1px;
        bottom: 0;
        margin: auto;
        margin-left: 5px;
      }
    }
  }

  #desktop-filter {
    position: relative;
    font-size: 0.875rem;
    border-bottom: 1px solid #000;
    z-index: 3;

    & >.container {
      display: flex;
      justify-content: space-between;
      align-items: center;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			padding: 0 1.5rem;

			@media screen and (min-width: helper.$md) {
				max-width: 940px;
			}

			@media screen and (min-width: helper.$lg) {
				max-width: 1340px;
			}

			@media screen and (min-width: helper.$xxl) {
				max-width: 1600px;
			}

			@media screen and (max-width: helper.$lg) {
				padding: 0 1rem !important;
			}
		}

    .filters {
      border-left: 1px solid helper.$black;

      .filter-2 {
        &:before {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          background: #2300FF;
          border-radius: 100%;
          top: 0;
          bottom: 0;
          margin: auto;
          margin-left: -4rem;
        }
      }
      .filter {
        position: relative;
        text-align: center;
        min-width: 16rem;
        line-height: 2.9rem;
        border-right: 1px solid helper.$black;
        cursor: pointer;

        a {
          width: 100%;
          height: 100%;
          display: block;
          position: relative;
          background: helper.$white;
          z-index: 10;
        }
      }
    }

    .date-filter {
      position: relative;
      text-align: right;
      min-width: 16rem;
      line-height: 2.9rem;
      padding-right: 1rem;
      cursor: pointer;
    }

    a {
      svg {
        width: 10px;
        position: absolute;
        top: -1px;
        bottom: 0;
        margin: auto;
        margin-left: 5px;
      }
    }

    .checkbox label {
      display: flex;
      height: 30px;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      input:checked ~ .checkbox {
        background-color: helper.$blue;
      }

      .checkbox {
        width: 15px;
        height: 15px;
        border: 1px solid #424242;
      }
    }

    .dropdown {
      margin-top: 1px;
      position: absolute;
      width: 101%;
      left: -0.5%;
      background: white;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      transition: opacity 0.3s ease;
      border: 1px solid black;
      border-top: 0;

      &.active {
        opacity: 1;
        visibility: visible;
        z-index: 3;
      }
    }
  }

  .filter-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: none;
    z-index: -1;

    &.active {
      display: block;
      z-index: 1;
    }
  }

  #filter-list {
    margin-top: 2rem;
    margin-bottom: -2rem;
    display: none;

    &.active {
      display: block !important;
    }

    & > .container {
      padding: 2rem;
      display: flex;
      align-items: center;
    }

    #clear-all {
      position: absolute;
      right: 1.5rem;
      color: helper.$blue;
      text-transform: uppercase;
    }

    #filter-buttons {

      &.active {

      }

      a {
        padding: 7px 16px;
        color: helper.$blue;
        border: 1px solid helper.$blue;
        border-radius: 20px;
        margin-right: 2rem;

        svg {
          width: 12px;
          margin-left: 10px;

          path fill {
            color: helper.$blue;
          }
        }
      }
    }

    @media screen and (max-width: helper.$lg) {
      margin-bottom: -2rem;
      
      & >.container {
        align-items: flex-start;
      }

      #filter-buttons {
        display: flex;
        flex-direction: column;
        a {
          margin: 0.5rem 0;
        }
      }
    }
  }

  .work-post {
    margin:auto;
    margin-bottom: 4rem;
    padding: 1.5rem;
    border: 1px solid helper.$white;
    @extend .fastTransition;

    &.Video {
      .date {
        padding-top: 1.1rem;
        br {
          display: none;
        }
      }

      .video-only {
        display: inline-block !important;
      }

      .main-content {
        border-top: 0;
        margin-top: 0;

        .post-heading {
          font-size: 1.438rem;
        }

        .post-blurb {
          display: none;
        }
      }
    }

    &:hover {
      color: initial;
      border-color: helper.$black;
    }

    .meta {
      .date {
        font-size: 0.875rem;
        font-family: 'IBMPlexMono';
        text-transform: uppercase;
        line-height: 1.2;
      }

      .authors {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.2;
      }
    }

    a {
      color: initial;
      padding: 0 1.5rem;
    }

    img {
      width: 100%;
      height: 17rem;
      object-fit: cover;
    }

    .main-content {
      position: relative;
      border-top: 1px solid black;
      margin-top: 2rem;
      padding-top: 2rem;
    }

    .cat {
      position: absolute;
      top: -15px;
      left: 0;
      display: flex;
      border-radius: 17px;
      font-size: 12px;
      text-transform: uppercase;
      padding: 6px 12px 6px 9px;

      background: #e1e1e1;

      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 15px;
        background: white;
        right: -15px;
        top: 0;
        z-index: 0;
      }

      span {
      font-size: 33px;
        line-height: 12px;
        margin-top: 4px;
        margin-right: 5px;
      }
    }

    .post-heading {
      font-size: 1.45rem;
      letter-spacing: 1.5;
    }

    @media screen and (max-width: helper.$lg) {
      padding: 1rem 0;

      img {
        min-height: 13rem;
      }
    }
  }

  .response {
    z-index: 1;
  }
}

#mobile-filter-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
	transform: translateY(-120%);
	z-index: 200;
	background: white;
	overflow-y: scroll;
  overflow-x: hidden;
	height: 100vh;
  @extend .fastTransition;

	.main-content {
    position: relative;
		display: flex;
		align-items: end;
		justify-content: end;
		min-height: 8rem;
		margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .heading {
    font-size: 5.6rem;
    letter-spacing: -4.5px;
    text-transform: uppercase;
    font-weight: 400;

    @media screen and (max-width: helper.$lg) {
      font-size: 3.125rem;
			letter-spacing: 0;
      text-align: right;
    }
  }

	.checkbox label {
    font-size: 1.25rem;
		display: flex;
		height: 37px;
		width: 100%;
		justify-content: space-between;
		align-items: center;

		input:checked ~ .checkbox {
			background-color: helper.$blue;
		}

		.checkbox {
			width: 20px;
			height: 20px;
			border: 1px solid #424242;
		}
	}

	#clear-all-2 {
    position: absolute;
    top: 0;
		left: 0;
		color: helper.$blue;
		text-transform: uppercase;
    @extend .fastTransition;
    display: none !important;

    transform: translateX(-300%);

    &.active {
      transform: translateX(0);
    }
  }

	&.active {
		transform: translateY(0%);
	}

	a.navbar-brand {

    img {
      height: 24px;
    }
  }

	#filter-close {
		background: none;
		z-index: 51;
		border: 0;
		outline: 0 !important;
		width: 20px;
		height: 20px;
		position: relative;
		z-index: 200;

		span {
			right: 0;
			width: 100%;
			height: 2px;
			background: helper.$black;
			position: absolute;
			@extend .fastTransition;

			&:nth-child(1) {
				margin-top: -4px;
			}

			&:nth-child(2) {
				margin-top: 4px;
			}
		}
		span:nth-child(1) {
			transform: rotate(45deg);
			width: 105%;
		}
		span:nth-child(2) {
			transform: rotate(-45deg) translateY(-6px) translateX(6px);
		}
	}

	.accordion {
		.accordion-item {
			border: 0;
			position: relative;

			&:after {
				border-bottom: 1px solid black;
				position: absolute;
				width: 150%;
				bottom: 0;
				left: -25%;
				content: "";
			}

			&:nth-last-of-type(1){
				&:after {
					display: none;
				}
			}

      @media screen and (max-width: helper.$lg) {
        .accordion-header {
          position: relative;

          &:after {
            border-bottom: 1px solid black;
            position: absolute;
            width: 150%;
            bottom: 0;
            left: -25%;
            content: "";
          }
        }

        .accordion-body {
          padding: 1rem 0;
        }
      }
		}

		.accordion-button {
			padding: 1.3rem 0;
			font-size: 1.4rem;
			box-shadow: none !important;
			background: none !important;
			color: helper.$black;
		}
	}
  .cta {
    margin-top: 2.5rem;
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;
    text-decoration: none !important;
    margin-bottom: 2.5rem;

    @media screen and (max-width: helper.$lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
