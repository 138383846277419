@use "../helper";

.collaborating-organizations {
	.container {
		padding-top: 5rem;
		padding-bottom: 4rem;
		border-top: 1px solid black;
	}

	.heading {
		max-width: 60%;
    font-size: 1.125rem;
    text-transform: uppercase;

		@media screen and (max-width: helper.$lg) {
			max-width: 100%;
			text-align: center;
		}
	}
	.imgs {
		overflow: hidden;

		.row {
			display: flex;
			flex-direction: row;
			flex-wrap: inherit;
			animation: scroll 80s infinite linear;
		}

		img {
			height: 40px;
			width: auto;
			margin-right: 4rem;
		}
	}
	@media screen and (max-width: helper.$lg) {
		padding: 5rem 0;
		border-top: 1px solid black;

		.container {
			border-top: 0;

			.imgs {
				overflow: visible;

				.row {
					animation: scroll 30s infinite linear;
				}

				img {
					height: 27px;
					margin-right: 2rem;
				}
			}
		}
	}
}


@keyframes scroll {
	from {margin-left: 0%}
	to {margin-left: -100%}
}
