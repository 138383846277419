@use "../helper";

.single:not(.single-work) {

  nav #menu-item-223 a {
    color: helper.$blue;
  }

  .blog-header {
    margin-top: 71px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    
    .breadcrumb {
      font-size: 0.875rem;
      color: helper.$blue;
      
      a {
        color: helper.$blue;
      }
    }

    .col-lg-5 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .blurb-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 4rem;

      @media screen and (max-width: helper.$lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .heading {
      font-size: 2.5rem;
      line-height: 1.2;

      @media screen and (max-width: helper.$lg) {
        font-size: 1.8rem;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .img-container {
      height: 34rem;
      position: relative;
      
      @media screen and (min-width: helper.$xxl) {
        height: 38rem;
      }
  
      @media screen and (max-width: helper.$lg) {
        height: 21rem;

        img {
          width: 110% !important;
          margin-left: -5%;
        }
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (max-width: helper.$lg) {
      margin-top: 71px;
      padding-top: 5rem;

      .blurb-section {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
  }


    .blog-content {
      padding-bottom: 3rem; 

      .container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
      }
      p {
        max-width: 78%;
      }

      .wp-block-image {
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 5rem;

        figure {
          display: flex;
          
          figcaption {
            padding-left: 2.5rem;
          }

          &.alignright {
            flex-direction: row-reverse;

            figcaption {
              padding-left: 0;
              padding-right: 2.5rem;
            }
          }
        }
      }

      @media screen and (max-width: helper.$lg) { 
        figure {
          margin: 0;
          flex-direction: column;
        }

        figcaption {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        
        img {
          max-height: 15rem;
          object-fit: cover;
        }

        p {
          max-width: 100%;
        }
      }
    }
}