@use "../helper";

.home {
  .navbar:not(.fixed) {
    border-bottom: 1px solid helper.$white;
    z-index: 6;

    a.navbar-brand {
      img {
        filter: grayscale(1) invert(1) contrast(10);
      }
    }  

    a {
      color: helper.$white;

      &:hover,
      &:focus {
        &:before {
          color: helper.$white;
        }
      }
    }
  }  
  #hamburger span, #hamburger2 span {
    background: white;
  }
}