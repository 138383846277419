@use "../helper";

.banner {
  padding: 10rem 0 4rem;
  display: flex;
  align-items: center;
  z-index: 5;
	border-bottom: 1px solid #000;
  display: -webkit-box !important;
  -webkit-box-pack: end !important;
  -webkit-box-align: end !important;

  .main-content {
    position: relative;
  }

  .heading {
    font-size: 5.6rem;
    letter-spacing: -4.5px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;

    @media screen and (max-width: helper.$lg) {
      font-size: 3.125rem;
      text-align: right;
      text-transform: none;
      max-width: 16rem;
      letter-spacing: -0.3px;
    }
  }

  .right-arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @media screen and (max-width: helper.$lg) {
    padding: 6rem 0 2.5rem;
    align-items: flex-end;

    .main-container {
      padding: 0 1rem !important;

      .main-content {
        height: 11rem;
        display: flex;
        align-items: end;
        justify-content: end;

        .right-arrow {
          bottom: initial;
          bottom: initial;
          right: initial;
          left: 0;
          transform: rotate(180deg);
          width: 25px;
        }
      }
    }
  }
}
