@use "../helper";

.section-cta {
  .cta {
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;

    @media screen and (max-width: helper.$lg) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}