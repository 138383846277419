@use "../helper";

.our-team {
	padding: 5rem 0;
	.heading {
		font-size: 1.125rem;
		text-transform: uppercase;
	}

	.blurb {
		max-width: 60%;
		font-size: 1.875rem;
		font-weight: 600;
		line-height: 1.2;
	}

	.team-members {
	  overflow: hidden;
		position: relative;

		.team-member-container {
			padding-top: 2.5rem;
			@extend .fastTransition;

			a.active {
				.read-bio {
					display: none; 
				}
			}

			@media screen and (min-width: helper.$lg) {
				&.deactive {
					// opacity: 0.3;
					// filter: blur(5px);
				}
			}
		}

		a.member {
			display: block;

			svg, span {
				@extend .slowTransition;
			}

			span {
				opacity: 0;
			}

			&:hover {

				@media screen and (min-width: helper.$lg) {
					span {
						opacity: 1;
					}
				}

				svg {
					transform: rotate(90deg);
					width: 14px !important;
				}
			}

			@media screen and (max-width: helper.$sm) {
				p {
					text-align: left;
				}
			}
		}

		img {
			height: 21.8rem;
			object-fit: cover;
			object-position: top;
			width: 100%;

			@media screen and (min-width: helper.$xxl) {
				height: 27rem;
			}
			@media screen and (max-width: helper.$lg) {
				height: auto;
			}
		}
		.name {
			font-size: 1.438rem;
			padding-right: 2rem;
			margin-bottom: 0;
			@media screen and (min-width: helper.$lg) {
				margin-right: 8rem;
			}
			@media screen and (max-width: helper.$lg) {
				padding-right: 0;
			}
		}
		.title {
			font-size: 1.125rem;
			font-weight: 600;
			margin-top: -5px;
		}
		.content {
			position: relative;
			padding-top: 1rem;

			.read-bio, .collapse {
				position: absolute;
				bottom: 0;
				right: 1rem;
				display: flex;
				align-items: center;

				&.collapse {
					top: 0;
					bottom: initial;
					right: 2rem;

					cursor: pointer;

					svg {
						transform: rotate(-90deg);
						width: 22px;
						height: auto;
						z-index: 10;
					}

					@media screen and (max-width: helper.$lg) {
						top: -3rem;
						right: 1rem;

						span {
							display: none !important;
						}
					}
				}

				span {
					font-weight: 600;
					color: helper.$blue;
					white-space: nowrap;
					margin-right: 0.8rem;
				}

				svg {
					width: 18px;
					path {
						fill: helper.$blue;
					}
				}
			}
		}
		.col-lg-4 {
			border-right: 1px so  lid black;
			margin-bottom: 6rem;
			position: relative;

			&:nth-last-child(1) {
				border-right: 0;
			}

			&:before {
				position: absolute;
				content: "";
				width: 10000%;
				bottom: -3rem;
				left: -2000%;
				border-top: 1px solid black;
			}

			.container {

			}

			&:nth-child(3n) {
				border-right: 0;
			}
		}
	}

	.member-details {
		padding-top: 2.5rem;
		border-top: 1px solid black;
		position: absolute;
		overflow: hidden;
    top: 0;
		left: 0;
		z-index: 1000;
    background: helper.$white;
		visibility: hidden;
		@extend .fastTransition;

		&.active {
			visibility: visible;
			position: relative;
		}

		&.section.active {
			visibility: hidden;
			display: none;

			@media screen and (max-width: helper.$lg) {
				visibility: visible;
				display: block;
			}
		}

		.name {
			font-size: 2.188rem;
			color: #343434;
		}

		.title {
			font-size: 1.438rem;
			color: black;
		}

		.team-blurb {
			font-size: 1.125rem;
			color: black;
		}

		.img-container {
			img {
				height: 27rem;

				@media screen and (min-width: helper.$xxl) {
					height: 29rem;
				}
			}
		}

		.content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}

		svg {
			height: 19px;
			path {
				fill: #2300FF;
			}
		}

		.collapse {
			text-align: right;
			svg {
				height: 35px;
			}
	}
	@media screen and (max-width: helper.$lg) {

		.heading {
			text-align: center;
			font-size: 1rem;
		}

		.blurb {
			text-align: center;
			font-size: 1.563rem;
			max-width: 100%;
		}

		.team-members {
			.name {
				font-size: 1.25rem;
			}
			.title {
				font-size: 1rem;
			}
		}


		.member-details {
			padding-top: 1rem;
			border-top: 0;
			overflow: visible;
			

			&.active {
				position: relative;
			}

			&.section.active {
				visibility: visible;
			}

			.content {
					&:before {
						content: "";
						position: absolute;
						background: white;
						width: 150%;
						height: 150%;
						z-index: 0;
					}
				}
			}

			.img-container img {
				height: 21.8rem;
			}

			 & > .row {
				width: 100%;
				margin: 0;

				div {
					padding-left: 0 !important;
					padding-right: 0 !important;
				}
			 }
		}
	}
}
