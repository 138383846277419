@use "../helper";

section.newsletter {
  padding: 5rem 0;
  border-top: 1px solid black;

  .heading {
    font-size: 1.875rem;
    line-height: 1.2;
  }

  .main-container {
    padding: 0 9rem !important;
  }

  .gform_fields, form {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: end;

    .ginput_container_text {
      @media screen and (min-width: helper.$lg) {
        margin-right: 2.5rem;
      }
    }

    input {
      width: 100%;
      min-width: 14rem;
      padding-bottom: 5px !important;
      padding-left: 0 !important;
      border: 0 !important;
      border-bottom: 1px solid black !important;
    }

    input[aria-invalid=true] {
      border-color: #c02b0a !important;
    }

    @media screen and (max-width: helper.$lg) {
      flex-direction: column;
    }
  }

  .cta {
    background: helper.$blue;
    color: helper.$white;
    border-color: helper.$blue;
    margin-left: 3.5rem;
    margin-bottom: 0;

    &:hover {
      svg {
        margin-left: 25px;
      }

      color: helper.$blue;
      background-color: white;
    }

    @media screen and (max-width: helper.$lg) {
      margin-left: 0;
    }
  }
  .gform_footer {

  }

  @media screen and (max-width: helper.$desktop) {

    .main-container {
      padding: 0 3rem !important;
    }

    .gform_fields, form {
      input {
        min-width: 13rem;
      }
    }
  }

  @media screen and (max-width: helper.$lg) {
    text-align: center;

    form {
      justify-content: center;
    }

    .gform_footer {
      margin-top: 2rem !important;
    }
  }
}

footer {
  background: helper.$blue;
  color: helper.$white;
  padding: 3rem 2rem;

  p, ul {
    margin-bottom: 0;
  }

  .container {
    padding: 0 1.5rem;
    @media screen and (min-width: helper.$md) {
      max-width: 940px;
    }

    @media screen and (min-width: helper.$lg) {
      max-width: 1340px;
    }

    @media screen and (min-width: helper.$xxl) {
      max-width: 1600px;
    }

    max-width: initial;

    @media screen and (max-width: helper.$lg) {
      width: 100% !important;
    }
  }

  .footer-row {
    display: flex;
    justify-content: space-between;
  }

  .underline {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0%;
      left: 0;
      bottom: 8px;
      height: 1px;
      background: white;
      @extend .fastTransition;
    }

    &:hover{
      &:before {
        width: 100%;
      }
    }
  }

  .copy-right {
    p {
      display: inline;
    }
  }

  p, a {
    font-weight: 600;
    color: helper.$white;
  }

  a {
    text-decoration: none;
  }

  .navbar-brand img {
    height: 1.313rem;
    filter: grayscale(1) invert(1) contrast(10);
  }

  .social li {
    svg {
      width: 1.2rem !important;
      position: relative !important;
    }
  }

  @media screen and (max-width: helper.$lg) {
    .footer-row, .social {
      flex-direction: column;
      text-align: center;
      font-size: 0.875rem;

      .social {
        padding: 3rem 0;
        li {
          margin-bottom: 1.2rem;
          margin-left: 0;
        }
      }

      .navbar-brand {
        margin-right: 0;
      }
    }
    .footer-row:nth-of-type(2) {
      flex-direction: column-reverse;
    }
  }
}
