.page-template-page-privacy {
  .main-contained-section {
    padding-left: 8rem;
    padding-right: 8rem;
    
    h2, p strong {
      font-weight: 500;
    }

    h2 {
      margin-top: 3rem;
      font-size: 1.5rem;
    }
    
    p {
      font-weight: 600;
    }

    p strong, p b {
      text-shadow: 0 0 1px;
    }
    ul {
      list-style: revert;
    }
    a {
      color: #2300FF; 
    }
  }
}