@use "../helper";

.who-we-are {
  background: helper.$blue;
  padding: 8rem 0;
  color: white;

  .heading {
    font-size: 1.125rem;
    text-transform: uppercase;
  }

  .img-container {
    img {
      width: 100%;
      height: 23rem;
      object-fit: cover;

      @media screen and (min-width: helper.$xxl) {
        height: 28rem;
      }
    }
  }

  .blurb {
    font-size: 1.43rem;
    letter-spacing: -0.23px;

    p {
      margin-bottom: 2rem;
      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: helper.$xxl) {
    .main-content {
      padding-left: 6rem;
    }
  }

  @media screen and (min-width: helper.$lg) {
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .col-lg-7 {
      align-items: center;
      display: flex;
    }
  }

  @media screen and (max-width: helper.$lg) {
    padding: 5rem 0;

    .img-container {
      img {
        width: 110% !important;
        height: 18rem;
        margin-left: -5%;
      }
    }

    .heading {
      text-align: center;
      margin-bottom: 3.5rem;
    }

    .blurb {
      padding-top: 5rem;
      font-size: 1.56rem;
      text-align: center;
    }
  }
}
