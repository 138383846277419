@use "../helper";
@import "~slick-carousel/slick/slick";

.slick-dots {
  display: flex;
  justify-content: center;

  li {
    margin: 0.5rem 1rem 0;
  }

  button {
    background: none;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    outline: none;
    border: 1px solid black;
    color: transparent;
    font-size: 0;
  }

  .slick-active button {
    background: helper.$blue;
    border-color: helper.$blue;
  }
}