@font-face {
  font-family: 'MaisonNeue';
  src: url('fonts/MaisonNeueWEB-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url('fonts/MaisonNeueWEB-Book.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexMono';
  src: url('fonts/IBMPlexMono-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}