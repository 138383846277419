@use "../helper";

.topic-slider {
  position: relative;
  padding: 8rem 0 4rem;

  @media screen and (max-width: helper.$lg) {
    padding: 5rem 0 2.5rem;

    .slick-slide {
      height: 500px;

      & > div {
        height: 100%;

        .topic {
          height: 100%;
          display: flex !important;
          align-items: center;
        }
      }
    }
  }
  
  .topics {
    img {
      width: 100%;
      height: 36rem;
      object-fit: fill;

      @media screen and (max-width: helper.$xl) {
        height: 28rem;
      }

      @media screen and (max-width: helper.$lg) {
        max-height: initial;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }

    .topic {
      position: relative;
    }
  }

  .slick-slide {
    position: relative;
    
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.3);

      &.dark-mode {
        background: rgba(0,0,0,0.3);
      }
    }
  }

  .main-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 32rem;
    margin-left: 3rem;

    &.dark-mode {
      p {
        filter: invert(1) contrast(100);
      }

      .cta {
        color: white;
        border-color: white;

        svg {
          path {
            fill: white;
          }
        }
        &:hover {
          color: white;
          border-color: white;
  
          svg {
            path {
              fill: white;
            }
          } 
        }
      }
    }

    .cta {
      &:hover {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        svg path {
          fill: inherit;
        }
      }
    }

    .heading {
      font-size: 3.125rem;
      line-height: 1;
    }

    .blurb {
      font-size: 1.125rem;
      letter-spacing: 0.5px;
    }

    .cta {
      margin-top: 2.5rem;
    }

    @media screen and (max-width: helper.$lg) {
      margin-left: 0;
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      transform: none;
      padding: 2rem 1rem;
      text-align: center;

      .heading {
        font-size: 2.2rem;
      }
      .heading {
        font-size: 2.2rem;
      }
      .blurb {
        font-size: 1rem;
      }
      .cta {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}